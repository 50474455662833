import { notification } from 'antd';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';

export interface ApiResponse<T = any> {
  status: string;
  message: string;
  data?: T;
}
const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem('authToken');
    if (token && config.url && config.url.includes('/api/')) {
      if (config.headers) {
        (config.headers as any).Authorization = `Bearer ${token}`;
      } else {
        (config.headers as any).headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (typeof error.response.data === 'string' && error.response.data.includes('Authorization')) {
          notification.warning({
            message: 'Acesso negado',
            description: "Por favor, faça login para acessar o sistema",
          });
          window.location.href = '/login';
        } else {
          notification.error({
            message: 'Erro',
            description: (error.response.data as { message: string }).message || 'Ocorreu um erro inesperado',
          });
        }
      } else {
        notification.error({
          message: 'Erro',
          description: (error.response.data as { message: string }).message || 'Ocorreu um erro inesperado',
        });
      }
    } else {
      notification.error({
        message: 'Erro de rede',
        description: 'Não foi possível conectar ao servidor. Verifique sua conexão de internet.',
      });
    }
    return Promise.reject(error);
  }
);


interface ApiService {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
}

const apiService: ApiService = {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return apiClient.get<T>(url, config);
  },
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return apiClient.post<T>(url, data, config);
  },
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return apiClient.put<T>(url, data, config);
  },
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return apiClient.delete<T>(url, config);
  },
};

export default apiService;
