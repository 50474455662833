import React from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { stateItems, statusItems, typeItems } from '../../../utils/utils';
import apiService, { ApiResponse } from '../../../services/apiService';
dayjs.locale('pt-br');

interface ModalContainerEditProps {
	visible: boolean;
	onClose: () => void;
	driverData: any;
}

const ModalContainerEdit: React.FC<ModalContainerEditProps> = ({ visible, onClose, driverData }) => {
	const [form] = Form.useForm();

	React.useEffect(() => {
		if (driverData) {
			form.setFieldsValue(driverData);
		}
	}, [driverData, form]);

	const handleSave = () => {
		form.validateFields()
			.then(async (values) => {
				try {
					const updatedContainerData = {
						...driverData, ...values

					};
					const response = await apiService.put('/api/container', updatedContainerData);
					notification.success({
						message: 'Sucesso',
						description: response.data.message,
					});
				} catch (error: any) {
					console.error('Erro ao atualizar container:', error);
				}
				onClose();
			}).catch((info) => {
				notification.error({
					message: 'Erro',
					description: 'Valide os campos obrigatórios',
				});
			});
	};

	return (
		<Modal
			title={`Editar container ${driverData.number}`}
			open={visible}
			onOk={handleSave}
			onCancel={onClose}
			okText="Salvar"
			cancelText="Cancelar"
		>
			<Form form={form} layout="vertical" >
				<Form.Item
					name="number"
					label="Número do Container"
					rules={[{ required: true, message: 'Por favor, insira o número do container' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="requerent"
					label="Requerente"
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					name="driver_name"
					label="Motorista"
					rules={[{ required: true, message: 'Por favor, insira o nome do motorista' }]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					name="transporter_name"
					label="Transportadora"
					rules={[{ required: true, message: 'Selecione a transportadora' }]}
				>
					<Input disabled />
				</Form.Item>
				<Form.Item
					name={'type'}
					label="Tipo do Container"
					rules={[{ required: true, message: 'Selecione o tipo do container' }]}
				>
					<Select placeholder="Selecione o tipo do container" style={{ width: '100%' }}>
						{typeItems.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>


				<Form.Item
					name={'state'}
					label="Estado do Container"
					rules={[{ required: true, message: 'Selecione o estado do container' }]}
				>
					<Select placeholder="Selecione o estado do container">
						{stateItems.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name={'status'}
					label="Status do registro"
					rules={[{ required: true, message: 'Selecione o status atual do container' }]}
				>
					<Select placeholder="Selecione o status do container">
						{statusItems.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalContainerEdit;
