import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { FaBars, FaTimes, FaBox, FaTruck, FaSignOutAlt, FaAddressCard, FaBuilding, FaTachometerAlt, FaDolly } from 'react-icons/fa';
import { getUserType } from '../utils/utils';
import ModalAddUser from './modals/user-add/ModalAddUser';
type ModalType = 'add' | null;
const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const userType = Number(getUserType());

  const [modalState, setModalState] = useState<{ type: ModalType }>({
    type: null,
  });

  const handleOpenModal = (type: ModalType) => {
    setModalState({ type });
  };

  const handleCloseModal = () => {
    setModalState({ type: null });
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('authToken');

    navigate('/login');
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-header">
          <h1 className={`sidebar-title ${isOpen ? 'visible' : 'hidden'}`}>Nexus Yard</h1>
          <button className="toggle-btn" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <Link to="/">
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaTachometerAlt /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Dashboard</span>
              </Link>
            </li>
            {userType === 1 && (
              <li className="sidebar-item" onClick={() => handleOpenModal('add')}>
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaAddressCard /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Adicionar Usuário</span>
              </li>)
            }

            <li>
              <Link to="/transporters">
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaBuilding /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Transportadoras</span>
              </Link>
            </li>
            <li>
              <Link to="/drivers">
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaTruck /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Motoristas</span>
              </Link>
            </li>
            <li>
              <Link to="/containers">
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaBox /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Containers</span>
              </Link>
            </li>
            <li>
              <Link to="/pickups">
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaDolly /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Coletas</span>
              </Link>
            </li>
          </ul>
          <ul>
            <li className="logout">
              <button onClick={logout} className="logout-button" title='Sair do sistema'>
                <span className={`icon ${isOpen ? 'visible' : 'hidden'}`}><FaSignOutAlt /></span>
                <span className={`text ${isOpen ? 'visible' : 'hidden'}`}>Deslogar</span>
              </button>

            </li>
          </ul>
        </nav>
      </div>
      {modalState.type === 'add' && (
        <ModalAddUser
          visible={modalState.type === 'add'}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Sidebar;
