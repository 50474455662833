import React from 'react';
import { Modal, Form, Input, notification } from 'antd';
import { NumericFormat } from 'react-number-format';
import apiService, { ApiResponse } from '../../../services/apiService';
import ReactInputMask from 'react-input-mask';


interface ModalTransporterEditProps {
	visible: boolean;
	onClose: () => void;
	driverData: any;
}

const ModalTransporterEdit: React.FC<ModalTransporterEditProps> = ({ visible, onClose, driverData }) => {
	const [form] = Form.useForm();

	React.useEffect(() => {
		if (driverData) {
			form.setFieldsValue(driverData);
		}
	}, [driverData, form]);

	const handleSave = async () => {
		form.validateFields()
			.then(async (values) => {
				try {
					const updatedTransporterData = {
						...driverData, ...values,
						price_empty: parseFloat(values.price_empty.replace(/[R$\s.]/g, '').replace(',', '.')),
						price_full: parseFloat(values.price_full.replace(/[R$\s.]/g, '').replace(',', '.')),
						price_five_days_empty: parseFloat(values.price_five_days_empty.replace(/[R$\s.]/g, '').replace(',', '.')),
						price_five_days_full: parseFloat(values.price_five_days_full.replace(/[R$\s.]/g, '').replace(',', '.')),
					};
					const response = await apiService.put('/api/transporter', updatedTransporterData);
					notification.success({
						message: 'Sucesso',
						description: response.data.message,
					});
				} catch (error: any) {
					console.error('Erro ao editar transportadora:', error);
				}
				onClose();
			}).catch((info) => {
				notification.error({
					message: 'Erro',
					description: 'Valide os campos obrigatórios',
				});
			});
	};

	return (
		<Modal
			title="Cadastro de transportadora"
			open={visible}
			onOk={handleSave}
			onCancel={onClose}
			okText="Salvar"
			cancelText="Cancelar"
		>
			<Form form={form} layout="vertical">
				<Form.Item
					name="name"
					label="Nome da transportadora"
					rules={[{ required: true, message: 'Por favor, coloque o nome da transportadora' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="cnpj"
					label="CNPJ"
					rules={[{
						required: true,
						message: 'Por favor, coloque o CNPJ da transportadora',
					}]}
				>
					<ReactInputMask
						mask="99.999.999/9999-99"
						maskChar={null}
						onChange={(e) => form.setFieldsValue({ cnpj: e.target.value })}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
						placeholder="00.000.000/0000-00"
					/>
				</Form.Item>

				<Form.Item
					name="contact"
					label="Contato"
					rules={[{ required: true, message: 'Por favor, coloque o número do telefone' }]}
				>
					<ReactInputMask
						mask="(99) 99999-9999"
						maskChar={null}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
					>
					</ReactInputMask>
				</Form.Item>

				<Form.Item
					name="address"
					label="Endereço"
					rules={[{ required: true, message: 'Por favor, coloque o endereço da transportadora' }]}
				>
					<Input />
				</Form.Item>


				<Form.Item
					name="price_five_days_empty"
					label="Preço fixo para 5 dias VAZIO"
					rules={[{ required: true, message: 'Por favor, coloque o valor' }]}
				>
					<NumericFormat
						thousandSeparator="."
						decimalSeparator=","
						prefix="R$ "
						decimalScale={2}
						// fixedDecimalScale
						allowNegative={false}
						onValueChange={(values) => form.setFieldsValue({ price_five_days_empty: values.value })}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
						placeholder="R$ 0,00"
					/>
				</Form.Item>

				<Form.Item
					name="price_five_days_full"
					label="Preço fixo para 5 dias CHEIO"
					rules={[{ required: true, message: 'Por favor, coloque o valor' }]}
				>
					<NumericFormat
						thousandSeparator="."
						decimalSeparator=","
						prefix="R$ "
						decimalScale={2}
						// fixedDecimalScale
						allowNegative={false}
						onValueChange={(values) => form.setFieldsValue({ price_five_days_full: values.value })}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
						placeholder="R$ 0,00"
					/>
				</Form.Item>

				<Form.Item
					name="price_empty"
					label="Preço/dia para cada container VAZIO"
					rules={[{ required: true, message: 'Por favor, coloque o valor' }]}
				>
					<NumericFormat
						thousandSeparator="."
						decimalSeparator=","
						prefix="R$ "
						decimalScale={2}
						// fixedDecimalScale
						allowNegative={false}
						onValueChange={(values) => form.setFieldsValue({ price_empty: values.value })}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
						placeholder="R$ 0,00"
					/>
				</Form.Item>

				<Form.Item
					name="price_full"
					label="Preço/dia para cada container CHEIO"
					rules={[{ required: true, message: 'Por favor, coloque o valor' }]}
				>
					<NumericFormat
						thousandSeparator="."
						decimalSeparator=","
						prefix="R$ "
						decimalScale={2}
						// fixedDecimalScale
						allowNegative={false}
						onValueChange={(values) => form.setFieldsValue({ price_full: values.value })}
						style={{ width: '100%', borderRadius: 4, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: 14 }}
						placeholder="R$ 0,00"
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalTransporterEdit;
