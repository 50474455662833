import React from 'react';
import { Modal, Form, Input, notification } from 'antd';
import ReactInputMask from 'react-input-mask';
import apiService, { ApiResponse } from '../../../services/apiService';

interface ModalEditDriverProps {
  visible: boolean;
  onClose: () => void;
  driverData: any;
}


const ModalEditDriver: React.FC<ModalEditDriverProps> = ({ visible, onClose, driverData }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (driverData) {
      form.setFieldsValue(driverData);
    }
  }, [driverData, form]);

  const handleSave = () => {
    form.validateFields()
      .then(async (values) => {
        try {
          const updatedDriverData = { ...driverData, ...values };
          const response = await apiService.put('/api/driver', updatedDriverData);
          notification.success({
            message: 'Sucesso',
            description: response.data.message,
          });
          onClose();
        } catch (error: any) {
          console.error('Erro ao editar motorista:', error);
        }
      }).catch((info) => {
        notification.error({
          message: 'Erro',
          description: 'Valide os campos obrigatórios',
        });
      });

  };

  return (
    <Modal
      title="Editar motorista"
      open={visible}
      onOk={handleSave}
      onCancel={onClose}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nome do motorista"
          rules={[{ required: true, message: 'Por favor, entre com o nome do motorista' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cpf"
          label="CPF"
          rules={[{ required: true, message: 'Por favor, insira o CPF do motorista' }]}
        >
          <ReactInputMask
            mask="999.999.999-99"
            maskChar={null}
            onChange={(e) => form.setFieldsValue({ cpf: e.target.value })}
            style={{ width: '100%', borderRadius: 6, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: '14px', background: '#ffffff' }}
            placeholder="000.000.000-00"
          />
        </Form.Item>


        <Form.Item
          name="contact"
          label="Contato"
          rules={[{ required: false, message: 'Por favor, insira o contato do motorista' }]}
        >
          <ReactInputMask
            mask="(99) 99999-9999"
            maskChar={null}
            placeholder="(00) 00000-0000"
            style={{ width: '100%', borderRadius: 6, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: '14px', background: '#ffffff' }}
          >
          </ReactInputMask>
        </Form.Item>


      </Form>
    </Modal>
  );
};

export default ModalEditDriver;
