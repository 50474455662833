import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import apiService from "../../services/apiService";

dayjs.locale('pt-br');

const ReportPDF: React.FC = () => {
	const reportRef = useRef<HTMLDivElement>(null);
	const monthName = dayjs().format('MMMM');
	const year = dayjs().format('YYYY');
	const [data, setData] = useState<{ company?: string; issue_date?: string; metrics?: any; containers_per_week?: any[]; transporters?: any[] }>({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await apiService.get(`/api/report`);
				console.log('Data fetched', response.data.data);
				setData(response.data.data);
			} catch (error) {
				console.error('Failed to fetch data', error);
			}
		};

		fetchData();
	}, [])

	const generatePDF = async () => {
		const input = reportRef.current;

		if (input) {
			const canvas = await html2canvas(input, { scale: 2 });
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("p", "mm", "a4");

			const imgWidth = 210;
			const pageHeight = 297;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;

			let heightLeft = imgHeight;
			let position = 0;

			pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				pdf.addPage();
				pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			}

			pdf.save(`Relatorio_GRP_${monthName}.pdf`);
		}
	};

	return (
		<div>
			<div style={{ textAlign: 'center', marginBottom: '20px' }}>
				<button onClick={generatePDF} style={{ padding: "10px 15px", fontSize: "16px", backgroundColor: "#28a745", color: "#fff", border: "none", borderRadius: "5px" }}>
					Gerar PDF
				</button>
			</div>

			<div
				ref={reportRef}
				style={{
					padding: "30px",
					fontFamily: "Arial, sans-serif",
					fontSize: "14px",
					lineHeight: "1.6",
					color: "#333",
					maxWidth: "800px",
					margin: "0 auto",
					border: "1px solid #ddd",
					borderRadius: "10px",
					boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
					backgroundColor: "#fefefe"
				}}
			>
				{/* Header */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "40px",
					}}
				>
					<h1 style={{ textAlign: "center", flexGrow: 1, fontSize: "24px" }}>
						Relatório Mensal - {monthName} {year}
					</h1>
				</div>

				{/* Informações da Empresa */}
				<div style={{ marginBottom: "30px" }}>
					<p>
						<img src="/logo_original.png" alt="Logo da Empresa" style={{ height: "100px" }} />
					</p>
					<p>
						<strong>Empresa:</strong> {data.company || 'N/A'}
					</p>
					<p>
						<strong>Data de Emissão:</strong> {data.issue_date}
					</p>
					<p>
						<strong>Gerado por:</strong> Sistema de gerenciamento de pátios Nexus Yard
					</p>
				</div>

				{/* Representação das Métricas de forma visual */}
				<section style={{ marginBottom: "40px" }}>
					<h2 style={{ fontSize: "20px", marginBottom: "20px" }}>Resumo das Métricas</h2>

					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
							textAlign: "center",
							marginBottom: "30px",
						}}
					>
						{/* Bloco 1 */}
						<div
							style={{
								backgroundColor: "#f0f0f0",
								padding: "20px",
								borderRadius: "10px",
								width: "30%",
							}}
						>
							<h3 style={{ fontSize: "16px" }}>Entradas no mês</h3>
							<p style={{ fontSize: "24px", fontWeight: "bold" }}>
								{data.metrics?.total_containers_entry_month}
							</p>
							<p style={{ fontSize: "14px", color: "#777" }}>Total de containers entrada</p>
						</div>

						{/* Bloco 2 */}
						<div
							style={{
								backgroundColor: "#f0f0f0",
								padding: "20px",
								borderRadius: "10px",
								width: "30%",
							}}
						>
							<h3 style={{ fontSize: "16px" }}>Finalizados no mês</h3>
							<p style={{ fontSize: "24px", fontWeight: "bold" }}>
								{data.metrics?.total_containers_month}
							</p>
							<p style={{ fontSize: "14px", color: "#777" }}>Total de containers finalizados</p>
						</div>

						{/* Bloco 3 */}
						<div
							style={{
								backgroundColor: "#f0f0f0",
								padding: "20px",
								borderRadius: "10px",
								width: "30%",
							}}
						>
							<h3 style={{ fontSize: "16px" }}>Valor recebido no Mês</h3>
							<p style={{ fontSize: "24px", fontWeight: "bold" }}>
								{data.metrics?.profit_month}
							</p>
							<p style={{ fontSize: "14px", color: "#777" }}>{monthName} {year}</p>
						</div>
					</div>
				</section>

				<section style={{ marginBottom: "40px" }}>
					<h2 style={{ fontSize: "20px", marginBottom: "20px" }}>
						Containers Recebidos por Semana
					</h2>

					<ul style={{ listStyle: "none", padding: 0 }}>
						{data.containers_per_week?.map((week, index) => (
							<li
								key={index}
								style={{
									backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
									padding: "15px",
									borderRadius: "8px",
									marginBottom: "10px",
									boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
								}}
							>
								<strong>
									Semana {index + 1}: {week.week.replaceAll("MONTH", monthName)}
								</strong>
								<p style={{ margin: "5px 0" }}>
									Containers Recebidos: <strong>{week.total}</strong>
								</p>
							</li>
						))}
					</ul>
				</section>

				{/* Transportadoras */}
				<section style={{ marginBottom: "40px" }}>
					<h2 style={{ fontSize: "20px", marginBottom: "20px" }}>Containers por Transportadora</h2>

					<ul style={{ listStyle: "none", padding: 0 }}>
						{data.transporters && data.transporters.map((carrier, index) => (
							<li
								key={index}
								style={{
									backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
									padding: "15px",
									borderRadius: "8px",
									marginBottom: "10px",
									boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
								}}
							>
								<strong>Transportadora: {carrier.name}</strong>
								<p style={{ margin: "5px 0" }}>
									Total de Containers: <strong>{carrier.containers_sent}</strong>
								</p>
							</li>
						))}
					</ul>
				</section>
			</div>
		</div>
	);
};

export default ReportPDF;
