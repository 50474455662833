import { jwtDecode } from "jwt-decode";

export const statusToLabel = (status: number): string => {
  switch (status) {
    case 0:
      return 'A RECEBER';
    case 1:
      return 'NO PATIO';
    case 2:
      return 'FINALIZADO';
    default:
      return 'DESCONHECIDO';
  }
};
export const typeToLabel = (type: number) => {
  switch (type) {
    case 0: return 'Outro';
    case 1: return 'DRY';
    case 2: return 'REFER';
    default: return 'Desconhecido';
  }
};

export const stateToLabel = (state: number) => {
  switch (state) {
    case 0: return 'VAZIO';
    case 1: return 'CHEIO';
    default: return 'Desconhecido';
  }
};

export const typeItems = [
  { id: 0, label: 'Outro' },
  { id: 1, label: 'DRY' },
  { id: 2, label: 'REFER' },
];

export const statusItems = [
  { id: 0, label: 'A RECEBER' },
  { id: 1, label: 'NO PATIO' },
  { id: 2, label: 'FINALIZADO' },
];

export const stateItems = [
  { id: 0, label: 'VAZIO' },
  { id: 1, label: 'CHEIO' },
];


interface DecodedToken {
  user_type?: string;
}

export const getUserType = (): string | null => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    return null;
  }

  try {
    const decodedToken: DecodedToken = jwtDecode(authToken);
    return decodedToken.user_type || null;
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return null;
  }
};