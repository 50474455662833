import React from 'react';
import { Modal, Form, Input, Select, Button, notification } from 'antd';
import apiService from '../../../services/apiService';

const { Option } = Select;

interface ModalAddUserProps {
	visible: boolean;
	onClose: () => void;
}

const ModalAddUser: React.FC<ModalAddUserProps> = ({ visible, onClose }) => {
	const [form] = Form.useForm();

	const handleFinish = async (values: any) => {
		try {
			const userData = { ...values, user_type: Number(values.userType) }
			const response = await apiService.post('/api/register/user', userData);
			notification.success({
				message: 'Sucesso',
				description: response.data.message,
			});
			onClose();
		} catch (error: any) {
			console.error('Erro ao realizar login:', error);
		}
	};

	return (
		<Modal
			title="Adicionar Usuário"
			open={visible}
			onCancel={onClose}
			footer={[
				<Button key="cancel" onClick={onClose}>
					Cancelar
				</Button>,
				<Button
					key="submit"
					type="primary"
					form="addUserForm"
					htmlType="submit"
				>
					Salvar
				</Button>
			]}
		>
			<Form
				id="addUserForm"
				form={form}
				layout="vertical"
				onFinish={handleFinish}
			>
				<Form.Item
					name="name"
					label="Nome"
					rules={[{ required: true, message: 'Por favor, insira o nome!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="email"
					label="Email"
					rules={[
						{ required: true, message: 'Por favor, insira o email!' },
						{ type: 'email', message: 'O email não é válido!' }
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="password"
					label="Senha"
					rules={[{ required: true, message: 'Por favor, insira a senha!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="userType"
					label="Tipo do Usuário"
					rules={[{ required: true, message: 'Por favor, selecione o tipo do usuário!' }]}
				>
					<Select placeholder="Selecione o tipo do usuário">
						<Option value="1">Admin</Option>
						<Option value="2">Operador</Option>
						<Option value="3">Transportadora</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalAddUser;
