import React, { useState } from 'react';
import { Modal, Form, DatePicker, notification, Typography, Row, Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import apiService from '../../../services/apiService';
import { stateToLabel } from '../../../utils/utils';
dayjs.locale('pt-br');

const { Title, Text } = Typography;

interface ModalContainerExitProps {
  visible: boolean;
  onClose: () => void;
  fetchData: (page: number, pageSize: number) => Promise<any>;
  initialContainerData: any;
  page: number;
  pageSize: number;
}

const ModalContainerExit: React.FC<ModalContainerExitProps> = ({ visible, onClose, fetchData, initialContainerData, page, pageSize }) => {
  const [form] = Form.useForm();
  const [finalPrice, setFinalPrice] = useState<number | null>(null);
  const [totalDays, setTotalDays] = useState<number | null>(null);
  const [containerData, setContainerData] = useState(initialContainerData);

  React.useEffect(() => {
    if (initialContainerData) {
      const exitDate = containerData.exit_date ? dayjs(containerData.exit_date) : dayjs();
      handleDateChange(exitDate)
      setContainerData({ ...initialContainerData });
      form.setFieldsValue({
        ...containerData,
        exit_date: exitDate
      });
    }
  }, [initialContainerData, form]);

  const disabledDate = (current: Dayjs) => {
    return current && current < dayjs(containerData.entry_date).startOf('day');
  };

  const handleDateChange = (exitDate: Dayjs | null) => {
    if (exitDate && containerData.entry_date) {
      const entryDate = dayjs(containerData.entry_date);
      const diffInDays = exitDate.diff(entryDate, 'day') + 1;
      setTotalDays(diffInDays)
      if (diffInDays > 5) {
        const plusDays = diffInDays - 5;
        const calculatedPrice = containerData.price_five_days + (plusDays * containerData.price_per_day);
        setFinalPrice(calculatedPrice);
      } else {
        const calculatedPrice = containerData.price_five_days
        setFinalPrice(calculatedPrice);
      }
    } else {
      setTotalDays(null)
      setFinalPrice(null);
    }
  };

  const handleSave = () => {
    form.validateFields()
      .then(async (values) => {
        try {
          const response = await apiService.put('/api/container/exit', {
            ...values,
            id: containerData.key,
            final_price: finalPrice,
            exit_date: values.exit_date
              ? dayjs(values.exit_date).toISOString()
              : null
          });
          notification.success({
            message: 'Sucesso',
            description: response.data.message,
          });
        } catch (error: any) {
          console.error('Erro ao registrar saida do container:', error);
        }
        await fetchData(page, pageSize);
        onClose();
      })
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Valide os campos obrigatórios',
        });
      });
  };

  return (
    <Modal
      title={<Title style={{ display: 'flex', justifyContent: 'center' }} level={4}>Informações do Container</Title>}
      open={visible}
      onOk={handleSave}
      onCancel={onClose}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Row gutter={[16, 16]} style={{ paddingTop: '20px' }}>
        <Col span={12}>
          <Text strong>Número:</Text> <Text>{containerData.number}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Transportadora:</Text> <Text>{containerData.transporter_name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Motorista:</Text> <Text>{containerData.driver_name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Estado do container:</Text> <Text>{stateToLabel(containerData.state)}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Preço 5 dias: </Text> <Text> R$ {containerData.price_five_days?.toFixed(2)}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Preço/dia após 5 dias: </Text> <Text> R$ {containerData.price_per_day?.toFixed(2)}</Text>
        </Col>
        <Col span={24}>
          <Text strong>Entrada: </Text>
          <Text>{dayjs(containerData.entry_date).isValid() ? dayjs(containerData.entry_date).format('DD/MM/YYYY [às] HH:mm') : 'Data inválida'}</Text>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '40px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 700 }}>Selecione a data de saída desse container para calcular o preço final</Text>
      </Row>
      <Row justify="center">
        <Form form={form} layout="vertical" style={{ marginTop: '20px' }}>
          <Form.Item
            name="exit_date"
            label="Data e Hora da Saída"
            rules={[{ required: true, message: 'Por favor, selecione a data e hora da saída' }]}
          >
            <DatePicker
              showTime
              format="DD/MM/YYYY HH:mm"
              className="datepicker-input"
              disabledDate={disabledDate}
              onChange={handleDateChange}
            />
          </Form.Item>
          {finalPrice !== null && (
            <>
              <Row justify="center" style={{ marginTop: '20px' }}>
                <Text>
                  Ficou por {totalDays} dias
                </Text>
              </Row>
              <Row justify="center" style={{ marginTop: '20px' }}>
                <Text strong style={{ fontSize: '16px' }}>
                  Preço Final: R$ {finalPrice.toFixed(2)}
                </Text>
              </Row>

            </>
          )}
        </Form>
      </Row>
    </Modal>
  );
};

export default ModalContainerExit;
