import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './LoginPage.css';
import apiService from '../../services/apiService';
import { Navigate, useNavigate } from 'react-router-dom';


const LoginPage: React.FC = () => {
	const [form] = Form.useForm();

	const navigate = useNavigate(); // Use o hook useNavigate

	const onFinish = async (values: any) => {
		try {
			const response = await apiService.post('/api/login', values);
			notification.success({
				message: 'Sucesso',
				description: response.data.message,
			});
			localStorage.setItem('authToken', response.data.data.token);
			navigate('/');
		} catch (error: any) {
			console.error('Erro ao realizar login:', error);
		}
	};


	return (
		<div className="login-container">
			<div className="login-form-wrapper">
				<div className="organization-logo-container">
					<img src="/grp.png" alt="Logo da Organização" className="organization-login" />
				</div>
				<Form
					form={form}
					name="login"
					className="login-form"
					initialValues={{ remember: true }}
					onFinish={onFinish}
				>
					<Form.Item
						name="email"
						rules={[{ required: true, message: 'Por favor, insira seu email!' }]}
					>
						<Input prefix={<UserOutlined />} placeholder="Email" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
					>
						<Input.Password prefix={<LockOutlined />} placeholder="Senha" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button">
							Entrar no sistema
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default LoginPage;
