import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Modal, Input, Row, Col, Form, notification, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ModalTransporterAdd from '../../components/modals/transporter-add/ModalTransporterAdd';
import ModalTransporterEdit from '../../components/modals/transporter-edit/ModalTransporterEdit';
import apiService, { ApiResponse } from '../../services/apiService';
const { Paragraph, Text } = Typography;
const { Column } = Table;

interface DataType {
  key: React.Key;
  name: string;
  cnpj: string;
  address: string;
  contact: string;
  price_full: number;
  price_empty: number;
}
type ModalType = 'edit' | 'delete' | 'add' | 'edit' | null;

const TransportersPage: React.FC = () => {
  const [modalState, setModalState] = useState<{ type: ModalType; item?: any }>({
    type: null,
    item: null,
  });
  const [data, setData] = useState<DataType[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({ name: '', cnpj: '' });

  const handleOpenModal = (type: ModalType, item: any) => {
    setModalState({ type, item });
  };

  const handleCloseModal = () => {
    setModalState({ type: null, item: null });
  };
  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await apiService.get(
        `/api/transporters?page=${page}&pageSize=${pageSize}&cnpj=${filter.cnpj}&name=${filter.name}`
      );
      setData(response.data.data);
      setPagination({
        current: page,
        pageSize,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (modalState.item) {
      try {
        const response = await apiService.delete(`/api/transporter?id=${modalState.item.key}`)
        notification.success({
          message: 'Sucesso',
          description: response.data.message,
        });
      } catch (error: any) {
        if (error.response && error.response.data) {
          const errorData: ApiResponse = error.response.data;
          notification.error({
            message: 'Erro',
            description: errorData.message || 'Ocorreu um erro inesperado',
          });
        } else {
          notification.error({
            message: 'Erro',
            description: 'Problema ao realizar operação',
          });
        }
      }
      fetchData(pagination.current, pagination.pageSize);
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, filter]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleFilterChange = (changedValues: any) => {
    setFilter({ ...filter, ...changedValues });
  };
  const formatCurrency = (value: number) => {
    return `R$ ${value.toFixed(2).replace('.', ',')}`;
  };
  return (
    <div style={{ padding: '16px' }}>
      <Form
        layout="inline"
        onValuesChange={(_, changedValues) => handleFilterChange(changedValues)}
        style={{ marginBottom: '16px' }}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Buscar por nome" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="CNPJ" name="cnpj">
              <Input placeholder="Buscar por CNPJ" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div style={{ marginBottom: 16, textAlign: 'right' }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleOpenModal('add', null)}
          style={{ backgroundColor: 'green', borderColor: 'green' }}
        >
          Cadastrar
        </Button>
      </div>

      <Table
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        size="middle"
        bordered
      >
        <Column title="Transportadora" dataIndex="name" key="name" />
        <Column title="CNPJ" dataIndex="cnpj" key="cnpj" />

        <Column
          title="Código de acesso"
          dataIndex="access_code"
          key="access_code"
          render={(text, record) => (
            <Space>
              <Paragraph copyable={{ text: text }}>
                <Text strong>{text}</Text>
              </Paragraph>
            </Space>
          )}
        />
        <Column title="Contato" dataIndex="contact" key="contact" />
        <Column title="Endereço" dataIndex="address" key="address" />
        <Column title="Preço fixo 5 dias Vazio" dataIndex="price_five_days_empty" key="price_five_days_empty" render={(text) => formatCurrency(text)} />
        <Column title="Preço fixo 5 dias Cheio" dataIndex="price_five_days_full" key="price_five_days_full" render={(text) => formatCurrency(text)} />
        <Column title="Preço Vazio/dia" dataIndex="price_empty" key="price_empty" render={(text) => formatCurrency(text)} />
        <Column title="Preço Cheio/dia" dataIndex="price_full" key="price_full" render={(text) => formatCurrency(text)} />
        <Column
          title="Ações"
          key="actions"
          render={(text, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => handleOpenModal('edit', record)}
                style={{ marginRight: 8 }}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleOpenModal('delete', record)}
              />
            </Space>
          )}
        />
      </Table>

      {modalState.type === 'add' && (
        <ModalTransporterAdd
          visible={modalState.type === 'add'}
          onClose={handleCloseModal}
          fetchData={fetchData}
          pageSize={pagination.pageSize}
          page={pagination.current}
        />
      )}

      {modalState.type === 'edit' && (
        <ModalTransporterEdit
          visible={modalState.type === 'edit'}
          onClose={handleCloseModal}
          driverData={modalState.item}
        />
      )}

      {modalState.type === 'delete' && (
        <Modal
          title="Excluir transportadora"
          onOk={handleDelete}
          open={modalState.type === 'delete'}
          onCancel={handleCloseModal}
        >
          <p>Tem certeza de que deseja excluir a transportadora {modalState.item?.name}?</p>
        </Modal>
      )}
    </div>
  );
};

export default TransportersPage;
