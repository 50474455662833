import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Statistic, Button } from 'antd';
import { DollarOutlined, ContainerOutlined, ArrowUpOutlined } from '@ant-design/icons';
import apiService from '../services/apiService';

type ReceivedContainersStates = {
  empty: number;
  full: number;
};

type DashboardAPI = {
  projected_earnings_today: number;
  actual_earnings_today: number;
  total_earnings: number;
  containers_processed_today: number;
  incoming_containers: number;
  received_containers: ReceivedContainersStates;
};

const Home: React.FC = () => {
  const [data, setData] = useState<DashboardAPI | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiService.get(`/api/dashboard`);
        setData(response.data.data);
      } catch (error) {
        console.error('Failed to fetch data', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: '30px' }}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Lucro Previsto do Dia"
              value={data.projected_earnings_today}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<DollarOutlined />}
              suffix="R$"
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card>
            <Statistic
              title="Lucro do Dia"
              value={data.actual_earnings_today}
              precision={2}
              valueStyle={{ color: '#1890ff' }}
              prefix={<DollarOutlined />}
              suffix="R$"
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card>
            <Statistic
              title="Lucro Total"
              value={data.total_earnings}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<DollarOutlined />}
              suffix="R$"
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Containers recebidos no dia"
              value={data.containers_processed_today}
              prefix={<ContainerOutlined />}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card>
            <Statistic
              title="Containers a receber no dia"
              value={data.incoming_containers}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Containers no Pátio">
            <Row>
              <Col span={12}>
                <Statistic
                  title="Vazio"
                  value={data.received_containers.empty}
                  valueStyle={{ color: '#cf1322' }}
                  prefix={<ContainerOutlined />}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Cheio"
                  value={data.received_containers.full}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ContainerOutlined />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: '20px', justifyContent: 'center' }}>
        <Col>
          <Button type="primary" size="large" onClick={() => window.open('/report', '_blank')}>
            Ver relatório
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
