import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import PrivateRoute from './PrivateRoutes';
import MainLayout from '../components/main-layout/MainLayout';
import ContainersPage from '../pages/containers/ContainersPage';
import DriversPage from '../pages/drivers/DriversPage';
import Registration from '../pages/register/Registration';
import TransportersPage from '../pages/transporters/TransportersPage';
import LoginPage from '../pages/login/LoginPage';
import PickupPage from '../pages/pickups/PickupPage';
import ReportPDF from '../components/report/ReportPDF';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/drivers"
          element={
            <PrivateRoute>
              <MainLayout>
                <DriversPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/containers"
          element={
            <PrivateRoute>
              <MainLayout>
                <ContainersPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/transporters"
          element={
            <PrivateRoute>
              <MainLayout>
                <TransportersPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/pickups"
          element={
            <PrivateRoute>
              <MainLayout>
                <PickupPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path='/register'
          element={
            <Registration />
          }
        />
        <Route
          path='/login'
          element={
            <LoginPage />
          }
        />
        <Route
          path='/report'
          element={
            <PrivateRoute>
              <ReportPDF />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
