import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Spin, Card, Space, DatePicker } from 'antd';
import ReactInputMask from 'react-input-mask';
import apiService from '../../../services/apiService';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { DebounceSelect } from './DebounceSelect';
import dayjs, { Dayjs } from 'dayjs';
// import { List } from 'lodash'; // Remove this import as it is not needed

interface ContainerValue {
	label: string;
	value: string;
}

export const RegisterPickup: React.FC = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
	const [transporterData, setTransporterData] = useState<any>(null);
	const [value, setValue] = useState<any[]>([]);

	async function fetchContainersList(number: string): Promise<ContainerValue[]> {
		try {

			const cnpj = form.getFieldValue('transporter_cnpj');
			const accessCode = form.getFieldValue('access_code');
			const response = await apiService.get(`/api/search/containers?number=${number}&cnpj=${cnpj}&access_code=${accessCode}`);
			const data = response.data.data
			return data.map((container: { id: string; number: string }) => ({
				label: container.number,
				value: container.id,
			}));
		} catch (error) {
			notification.error({
				message: 'Erro ao buscar containers',
				description: 'Não foi possível carregar os containers. Tente novamente mais tarde.',
			});
			return [];
		}
	}
	const disabledDate = (current: Dayjs) => {
		return current && current < dayjs().startOf('day');
	};

	const handleSearch = async () => {
		try {
			setLoading(true);
			const cnpj = form.getFieldValue('transporter_cnpj');
			const accessCode = form.getFieldValue('access_code');
			const response = await apiService.get(`/api/search/transporter?cnpj=${cnpj}&access_code=${accessCode}`);
			const transporter = response.data.data;
			setTransporterData(transporter);
			form.setFieldsValue({
				transporter_name: transporter.name,
				transporter_address: transporter.address,
			});
			setAdditionalFieldsVisible(true);
			notification.success({
				message: 'Transportadora encontrada',
				description: 'Informações carregadas com sucesso!',
			});
		} catch (error) {
			notification.error({
				message: 'Erro ao carregar informações',
				description: 'Falha ao buscar dados da transportadora. Verifique o CNPJ e o código de acesso.',
			});
		} finally {
			setLoading(false);
		}
	};

	// Função para enviar os dados de coleta
	const onFinish = async (values: any) => {
		try {
			const dataPickup = {
				driver_name: values.driver_name,
				driver_cpf: values.driver_cpf,
				access_code: values.access_code,
				driver_placas: values.driver_placas,
				transporter_id: transporterData.id,
				driver_contact: values.driver_contact,
				pickup_date: values.pickup_date
					? dayjs(values.pickup_date).toISOString()
					: null,
				containers: value,
			};
			const response = await apiService.post('/api/register/pickup', dataPickup);
			notification.success({
				message: 'Sucesso',
				description: response.data.message,
			});
			form.resetFields();
			setAdditionalFieldsVisible(false);
		} catch (error) {
			notification.error({
				message: 'Erro ao enviar',
				description: 'Falha ao enviar o pedido de coleta. Verifique os dados e tente novamente.',
			});
		}
	};

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			autoComplete="off"
			initialValues={{
				driver_placas: [{ placa: '' }],
			}}
		>
			<section>
				<Title level={4} className="section-title">Dados da Transportadora</Title>

				<Form.Item
					name="transporter_cnpj"
					label="CNPJ da Transportadora"
					rules={[{ required: true, message: 'Por favor, insira o CNPJ' }]}
				>
					<ReactInputMask
						mask="99.999.999/9999-99"
						maskChar={null}
						onChange={(e) => form.setFieldsValue({ transporter_cnpj: e.target.value })}
						style={{
							width: '100%',
							borderRadius: 6,
							border: '1px solid #d9d9d9',
							padding: '4px 11px',
							fontSize: '14px',
							background: '#ffffff'
						}}
						placeholder="00.000.000/0000-00"
					/>
				</Form.Item>

				<Form.Item
					name="access_code"
					label="Código de Acesso"
					rules={[{ required: true, message: 'Por favor, insira o código de acesso' }]}
					style={{ width: '40%' }}
				>
					<Input placeholder="Digite o código de acesso" />
				</Form.Item>

				<Form.Item>
					<Button type="primary" block onClick={handleSearch} disabled={loading}>
						{loading ? <Spin /> : 'Carregar Informações'}
					</Button>
				</Form.Item>
			</section>

			{additionalFieldsVisible && transporterData && (
				<section>
					<Form.Item name="transporter_name" label="Nome da Transportadora">
						<Input value={transporterData.name} disabled />
					</Form.Item>

					<Form.Item name="transporter_address" label="Endereço da Transportadora">
						<Input value={transporterData.address} disabled />
					</Form.Item>

					<Title level={4} className="section-title">Motorista para Coleta</Title>

					<Form.Item
						name="driver_name"
						label="Nome do Motorista"
						rules={[{ required: true, message: 'Por favor, insira o nome do motorista' }]}
					>
						<Input placeholder="Digite o nome do motorista" />
					</Form.Item>

					<Form.Item
						name="driver_cpf"
						label="CPF"
						rules={[{ required: true, message: 'Por favor, insira o CPF do motorista' }]}
					>
						<ReactInputMask
							mask="999.999.999-99"
							maskChar={null}
							style={{
								width: '100%',
								borderRadius: 6,
								border: '1px solid #d9d9d9',
								padding: '4px 11px',
								fontSize: '14px',
								background: '#ffffff',
							}}
							placeholder="000.000.000-00"
						/>
					</Form.Item>

					<Form.Item name="driver_contact" label="Contato">
						<ReactInputMask
							mask="(99) 99999-9999"
							maskChar={null}
							placeholder="(00) 00000-0000"
							style={{
								width: '100%',
								borderRadius: 6,
								border: '1px solid #d9d9d9',
								padding: '4px 11px',
								fontSize: '14px',
								background: '#ffffff',
							}}
						/>
					</Form.Item>

					<Form.List name="driver_placas">
						{(fields, { add, remove }) => (
							<>
								{fields.map((field) => (
									<Card key={field.key} className="item-card" bordered={false} bodyStyle={{ padding: '16px' }}>
										<Space style={{ width: '100%', justifyContent: 'space-between' }} align="baseline">
											<Form.Item
												{...field}
												label="Placa"
												name={[field.name, 'placa']}
												rules={[{ required: true, message: 'Por favor, insira a placa' }]}
												style={{ margin: 0, flex: 1 }}
											>
												<Input placeholder="Digite a placa" />
											</Form.Item>
											{fields.length > 1 && (
												<MinusCircleOutlined onClick={() => remove(field.name)} className="remove-icon" />
											)}
										</Space>
									</Card>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}
										className="add-button"
									>
										Adicionar placas
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>


					<Form.Item name="containers" label="Containers para Coleta"
						rules={[{ required: true, message: 'Por favor, selecione um container' }]}>
						<DebounceSelect
							mode="multiple"
							value={value}
							placeholder="Selecione os containers"
							fetchOptions={fetchContainersList}
							onChange={(newValue) => {
								const containerIds = newValue.map((container: ContainerValue) => container.value);
								setValue(containerIds);
							}}
							style={{ width: '100%' }}
						/>
					</Form.Item>

					<Form.Item
						name={'pickup_date'}
						label="Data/Hora para coleta dos containers"
						rules={[{ required: true, message: 'Por favor, selecione a data prevista da coleta' }]}
					>
						<DatePicker disabledDate={disabledDate} showTime format="DD/MM/YYYY HH:mm" className="datepicker-input" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" block className="submit-button">
							Enviar Pedido de Coleta
						</Button>
					</Form.Item>
				</section>
			)}
		</Form>
	);
};
