import React, { useState } from 'react';
import { Form, Input, Button, Space, Typography, Divider, Card, Select, Row, Col, DatePicker, notification, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './Registration.css';
import { stateItems, typeItems } from '../../utils/utils';
import apiService, { ApiResponse } from '../../services/apiService';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import ReactInputMask from 'react-input-mask';
import { RegisterEntry } from './entry/RegisterEntry';
import { RegisterPickup } from './pickup/RegisterPickup';
const { Paragraph, Text } = Typography;
const { Title } = Typography;
const Registration: React.FC = () => {
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    form.validateFields()
      .then(async (values) => {
        try {
          const dataTransporter = {
            ...values,
            estimated_entry_date: values.estimated_entry_date
              ? dayjs(values.estimated_entry_date).toISOString()
              : null
          }
          const response = await apiService.post('/api/register/transporter', dataTransporter);
          notification.success({
            message: 'Sucesso',
            description: response.data.message,
          });
          form.resetFields();
        } catch (error: any) {
          console.error('Erro ao registrar entrada para o patio:', error);
        }
      }).catch((info) => {
        notification.error({
          message: 'Erro',
          description: 'Ocorreu um erro ao tentar registrar o transportador',
        });
      });
  };
  const [formType, setFormType] = useState<'entrada' | 'coleta'>('entrada');
  const handleFormChange = (value: 'entrada' | 'coleta') => {
    setFormType(value);
    form.resetFields();
  };
  return (
    <div className="background-animation">
      <div className="form-container">
        <div className="organization-logo-container">
          <img src="/grp.png" alt="Logo da Organização" className="organization-logo" />
        </div>

        <Title level={3} className="form-title">
          Registro pátio GRP
        </Title>

        <Select
          value={formType}
          onChange={handleFormChange}
          style={{ marginBottom: '20px' }}
        >
          <Select.Option value="entrada">Registrar Entrada</Select.Option>
          <Select.Option value="coleta">Registrar Coleta</Select.Option>
        </Select>

        {formType === 'entrada' && <RegisterEntry />}
        {formType === 'coleta' && <RegisterPickup />}

        <div className="header-logo-container">
          <img src="/nexusyard.png" alt="Logo do Site" className="header-logo" />
          <p className="nexus-description">Software para gerenciamento de pátios</p>
          <p className="nexus-description">Deseja melhorar o gerenciamento do seu pátio com a Nexus Yard?</p>
          <p className="nexus-description"> Envie um email e peça uma amostra</p>
          <Tooltip>
            <Paragraph copyable={{ text: 'yardnexus@gmail.com' }}>
              <Text strong>yardnexus@gmail.com</Text>
            </Paragraph>
          </Tooltip>
        </div>
      </div>
    </div >
  );
};

export default Registration;
