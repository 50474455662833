import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Input, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalEditDriver from '../../components/modals/driver-edit/ModalEditDriver';
import apiService, { ApiResponse } from '../../services/apiService';

const { Column } = Table;

interface DataType {
  key: React.Key;
  name: string;
  cpf: string;
  placas: string[];
  contact: string;
}
type ModalType = 'edit' | 'delete' | 'entry' | 'exit' | null;
const DriversPage: React.FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [filter, setFilter] = useState({ name: '', cpf: '' });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<{ type: ModalType; item?: any }>({
    type: null,
    item: null,
  });
  const handleOpenModal = (type: ModalType, item: any) => {
    setModalState({ type, item });
  };

  const handleCloseModal = () => {
    setModalState({ type: null, item: null });
  };

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await apiService.get(
        `/api/drivers?page=${page}&pageSize=${pageSize}&cpf=${filter.cpf}&name=${filter.name}`
      );
      setData(response.data.data);
      setPagination({
        current: page,
        pageSize,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, filter]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleFilterChange = (changedValues: any) => {
    setFilter({ ...filter, ...changedValues });
  };


  const handleDelete = async () => {
    if (modalState.item) {
      try {
        const response = await apiService.delete(`/api/driver?id=${modalState.item.key}`)
        notification.success({
          message: 'Sucesso',
          description: response.data.message,
        });
      } catch (error: any) {
        console.error('Erro ao realizar login:', error);
      }
      fetchData(pagination.current, pagination.pageSize);
      handleCloseModal();
    }
  };
  return (
    <div style={{ padding: '16px' }}>
      <Form
        layout="inline"
        onValuesChange={(_, changedValues) => handleFilterChange(changedValues)}
        style={{ marginBottom: '16px' }}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Buscar por nome" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="CPF" name="cpf">
              <Input placeholder="Buscar por CPF" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        size="middle"
        bordered
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="CPF" dataIndex="cpf" key="cpf" />
        <Column
          title="Placas"
          dataIndex="placas"
          key="placas"
          render={(placas: string[]) => (
            <>
              {placas.map((placa) => (
                <Tag color="blue" key={placa}>
                  {placa}
                </Tag>
              ))}
            </>
          )}
        />
        <Column title="Contato" dataIndex="contact" key="contact" />
        <Column
          title="Actions"
          key="actions"
          render={(text, record) => (
            <div className="actions">
              <Button
                icon={<EditOutlined />}
                onClick={() => handleOpenModal('edit', record)}
                style={{ marginRight: 8 }}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleOpenModal('delete', record)}
              />
            </div>
          )}
        />
      </Table>
      {modalState.type === 'edit' && (
        <ModalEditDriver
          visible={modalState.type === 'edit'}
          onClose={handleCloseModal}
          driverData={modalState.item}
        />
      )}
      {modalState.type === 'delete' && (
        <Modal
          title="Excluir motorista"
          open={modalState.type === 'delete'}
          onOk={handleDelete}
          onCancel={handleCloseModal}
        >
          <p>Tem certeza de que deseja excluir o motorista {modalState.item?.name}?</p>
        </Modal>
      )}
    </div>
  );
};

export default DriversPage;
