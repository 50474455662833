import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, notification, Input, Select, Form, Row, Col, Typography } from 'antd';
import apiService, { ApiResponse } from '../../services/apiService';
import dayjs from 'dayjs';
import { FaCheckCircle } from 'react-icons/fa';
const { Text } = Typography;
const finishedFilters = [
	{ id: 0, label: 'PARA COLETA' },
	{ id: 1, label: 'FINALIZADO' },
];
type ModalType = 'finished' | null;

const PickupPage: React.FC = () => {
	const [filters, setFilters] = useState({ driver_name: '', requerent: '', number: '', transporter_name: '', finished: 0 });
	const [modalState, setModalState] = useState<{ type: ModalType; item?: any }>({
		type: null,
		item: null,
	});

	const [data, setData] = useState<any[]>();
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0
	});


	const handleOpenModal = (type: ModalType, item: any) => {
		if (type === 'finished' && item?.status !== 2) {
			notification.warning({
				message: 'Ação não permitida',
				description: `Você precisa primeiramente registrar a saída do container ${item?.number} antes de concluir essa coleta. Volte para a página de containers e registre a saída do container.`,
			});
			return;
		}
		setModalState({ type, item });
	};

	const handleCloseModal = () => {
		setModalState({ type: null, item: null });
	};


	const handleDelete = async () => {
		if (modalState.item) {
			try {
				const response = await apiService.delete(`/api/pickup?id=${modalState.item.key}`)
				notification.success({
					message: 'Sucesso',
					description: response.data.message,
				});
			} catch (error: any) {
				if (error.response && error.response.data) {
					const errorData: ApiResponse = error.response.data;
					notification.error({
						message: 'Erro',
						description: errorData.message || 'Ocorreu um erro inesperado',
					});
				} else {
					notification.error({
						message: 'Erro',
						description: 'Problema ao realizar operação',
					});
				}
			}
			fetchData(pagination.current, pagination.pageSize);
			handleCloseModal();
		}
	};

	useEffect(() => {
		fetchData(pagination.current, pagination.pageSize);
	}, [pagination.current, pagination.pageSize]);



	const handleFilterChange = (value: any, key: keyof typeof filters) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[key]: value,
		}));
	};


	const applyFilters = () => {
		fetchData(pagination.current, pagination.pageSize);
	};

	const fetchData = async (page: number, pageSize: number) => {
		setLoading(true);
		try {
			const response = await apiService.get(
				`/api/pickups?page=${page}&pageSize=${pageSize}&finished=${filters.finished}&requerent=${filters.requerent}&driver_name=${filters.driver_name}&number=${filters.number}&transporter_name=${filters.transporter_name}`
			);
			setData(response.data.data);
			setPagination({
				current: page,
				pageSize,
				total: response.data.total,
			});
		} catch (error) {
			console.error('Failed to fetch data', error);
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination: any) => {
		setPagination(pagination);
	};


	return (
		<div className="containers-page">
			<h1>Registro de coletas</h1>
			<Form layout="vertical" style={{ marginBottom: 16 }}>
				<Row gutter={16}>
					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '12px' }}>
						<Form.Item label="Número" style={{ marginBottom: 8 }}>
							<Input
								placeholder="Número"
								value={filters.number}
								onChange={(e) => handleFilterChange(e.target.value, 'number')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '10px' }}>
						<Form.Item label="Requerente" style={{ marginBottom: 8 }}>
							<Input
								placeholder="Requerente"
								value={filters.requerent}
								onChange={(e) => handleFilterChange(e.target.value, 'requerent')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ width: '20px', marginRight: '12px' }}>
						<Form.Item label="Motorista" style={{ marginBottom: 4 }}>
							<Input
								placeholder="Nome do motorista"
								value={filters.driver_name}
								onChange={(e) => handleFilterChange(e.target.value, 'driver_name')}
							/>
						</Form.Item>
					</Col>

					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '10px' }}>
						<Form.Item label="Transportadora" style={{ marginBottom: 8 }}>
							<Input
								placeholder="Transportadora"
								value={filters.transporter_name}
								onChange={(e) => handleFilterChange(e.target.value, 'transporter_name')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '12px' }}>
						<Form.Item label="Status da coleta" style={{ marginBottom: 8 }}>
							<Select
								placeholder="Status da coleta"
								value={filters.finished}
								onChange={(value) => handleFilterChange(value, 'finished')}
								style={{ width: '100%' }}
							>
								{finishedFilters.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Form.Item style={{ marginBottom: 0 }}>
							<Button onClick={applyFilters} type="primary" style={{ width: '100%' }}>Filtrar</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				dataSource={data}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: pagination.total,
				}}
				loading={loading}
				onChange={handleTableChange}
			>
				<Table.Column title="Número" dataIndex="number" key="number" />
				<Table.Column title="Requerente" dataIndex="requerent" key="requerent" />
				<Table.Column title="Motorista" dataIndex="driver_name" key="driver_name" />
				<Table.Column title="Transportadora" dataIndex="transporter_name" key="transporter_name" />
				<Table.Column title="Coleta programada" dataIndex="pickup_date" key="pickup_date" render={(pickup_date) => dayjs(pickup_date).isValid() ? dayjs(pickup_date).format('DD/MM/YYYY [às] HH:mm') : 'Data inválida'} />
				<Table.Column
					title="Ações"
					key="actions"
					render={(text, record) => {
						let buttonColor = '';
						if (record.status === 0) {
							buttonColor = 'blue';
						} else if (record.status === 1) {
							buttonColor = 'green';
						}

						return (
							<div className="actions">
								{(record.finished === false) ? (
									<Button
										title='Concluir coleta'
										icon={<FaCheckCircle />}
										onClick={() => handleOpenModal('finished', record)}
										style={{ marginRight: 8 }}
									/>
								) : <Text> Coletado</Text>}

							</div>
						);
					}}
				/>
			</Table>


			{modalState.type === 'finished' && (
				<Modal
					title="Confirmar container como coletado"
					open={modalState.type === 'finished'}
					onOk={handleDelete}
					onCancel={handleCloseModal}
				>
					<p>Tem certeza que o {modalState.item?.number} já foi coletado?</p>
				</Modal>
			)}

		</div>
	);
};

export default PickupPage;
