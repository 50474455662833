import React from 'react';
import { Form, Input, Button, Space, Card, Row, Col, DatePicker, Select, Divider, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import dayjs from 'dayjs';
import apiService from '../../../services/apiService';
import Title from 'antd/es/typography/Title';
import { stateItems, typeItems } from '../../../utils/utils';

export const RegisterEntry: React.FC = () => {
	const [form] = Form.useForm();

	const onFinish = async (values: any) => {
		try {
			const dataTransporter = {
				...values,
				estimated_entry_date: values.estimated_entry_date
					? dayjs(values.estimated_entry_date).toISOString()
					: null
			};
			const response = await apiService.post('/api/register/transporter', dataTransporter);
			notification.success({
				message: 'Sucesso',
				description: response.data.message,
			});
			form.resetFields();
		} catch (error) {
			notification.error({
				message: 'Erro',
				description: 'Ocorreu um erro ao tentar registrar o transportador',
			});
		}
	};

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			autoComplete="off"
			initialValues={{
				driver_placas: [{ placa: '' }],
				containers: [{ number: '', type: '', state: '' }]
			}}
		>
			<section>
				<Title level={4} className="section-title">
					Dados da Transportadora
				</Title>

				<Form.Item
					name="transporter_cnpj"
					label="CNPJ da transportadora"
					rules={[{ required: true, message: 'Por favor, insira o CNPJ' }]}
				>
					<ReactInputMask
						mask="99.999.999/9999-99"
						maskChar={null}
						onChange={(e) => form.setFieldsValue({ cnpj: e.target.value })}
						style={{ width: '100%', borderRadius: 6, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: '14px', background: '#ffffff' }}
						placeholder="00.000.000/0000-00"
					/>
				</Form.Item>
				<Form.Item
					name="access_code"
					label="Código de Acesso"
					rules={[{ required: true, message: 'Por favor, insira o código de acesso' }]}
					style={{ width: '40%' }}
				>
					<Input placeholder="Digite o código de acesso" />
				</Form.Item>
				<Form.Item
					name="requerent"
					label="Requerente"
					rules={[{ required: true, message: 'Por favor, insira o requerente' }]}
				>
					<Input placeholder="Digite o nome do requerente" />
				</Form.Item>
			</section>


			<Divider />

			<section>
				<Title level={4} className="section-title">
					Dados do Motorista
				</Title>
				<Form.Item
					name="driver_name"
					label="Nome do motorista"
					rules={[{ required: true, message: 'Por favor, insira o nome do motorista' }]}
				>
					<Input placeholder="Digite o nome do motorista" />
				</Form.Item>
				<Form.Item
					name="driver_cpf"
					label="CPF"
					rules={[{ required: true, message: 'Por favor, insira o CPF do motorista' }]}
				>
					<ReactInputMask
						mask="999.999.999-99"
						maskChar={null}
						style={{ width: '100%', borderRadius: 6, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: '14px', background: '#ffffff' }}
						placeholder="000.000.000-00"
					/>
				</Form.Item>
				<Form.Item
					name="driver_contact"
					label="Contato"
				>
					<ReactInputMask
						mask="(99) 99999-9999"
						maskChar={null}
						placeholder="(00) 00000-0000"
						style={{ width: '100%', borderRadius: 6, border: '1px solid #d9d9d9', padding: '4px 11px', fontSize: '14px', background: '#ffffff' }}
					/>
				</Form.Item>

				<Form.List name="driver_placas">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => (
								<Card key={field.key} className="item-card" bordered={false} bodyStyle={{ padding: '16px' }}>
									<Space style={{ width: '100%', justifyContent: 'space-between' }} align="baseline">
										<Form.Item
											{...field}
											label="Placa"
											name={[field.name, 'placa']}
											rules={[{ required: true, message: 'Por favor, insira a placa' }]}
											style={{ margin: 0, flex: 1 }}
										>
											<Input placeholder="Digite a placa" />
										</Form.Item>
										{fields.length > 1 && (
											<MinusCircleOutlined onClick={() => remove(field.name)} className="remove-icon" />
										)}
									</Space>
								</Card>
							))}
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
									className="add-button"
								>
									Adicionar placas
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>

				<Form.List name="containers">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => (
								<Card key={field.key} className="item-card" bordered={false} bodyStyle={{ padding: '16px' }}>
									<Row gutter={[16, 8]} className="container-row">
										<Col xs={24} sm={10}>
											<Form.Item
												{...field}
												label="Número do container"
												name={[field.name, 'number']}
												rules={[{ required: true, message: 'Por favor, insira o código do container' }]}
											>
												<Input placeholder="Digite o código do container" />
											</Form.Item>
										</Col>
										<Col xs={24} sm={7}>
											<Form.Item
												{...field}
												name={[field.name, 'type']}
												label="Tipo"
												rules={[{ required: true, message: 'Selecione o tipo do container' }]}
											>
												<Select placeholder="Selecione o tipo do container">
													{typeItems.map((item) => (
														<Select.Option key={item.id} value={item.id}>
															{item.label}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} sm={7}>
											<Form.Item
												{...field}
												name={[field.name, 'state']}
												label="Estado"
												rules={[{ required: true, message: 'Selecione o estado atual do container' }]}
											>
												<Select placeholder="Selecione o estado do container">
													{stateItems.map((item) => (
														<Select.Option key={item.id} value={item.id}>
															{item.label}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									{fields.length > 1 && (
										<MinusCircleOutlined onClick={() => remove(field.name)} className="remove-icon" />
									)}
								</Card>
							))}
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
									className="add-button"
								>
									Adicionar containers
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>

			</section>

			<Form.Item
				name={'estimated_entry_date'}
				label="Data e Hora de entrada prevista"
				rules={[{ required: true, message: 'Por favor, selecione a data de entrada prevista' }]}
			>
				<DatePicker showTime format="DD/MM/YYYY HH:mm" className="datepicker-input" />
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit" block className="submit-button">
					Enviar informação para o pátio
				</Button>
			</Form.Item>
		</Form>
	);
};
