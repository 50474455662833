import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, notification, Input, Select, Form, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, FlagOutlined } from '@ant-design/icons';
import ModalContainerEntry from '../../components/modals/container-entry/ModalContainerEntry';
import ModalContainerExit from '../../components/modals/container-exit/ModalContainerExit';
import { stateToLabel, statusToLabel, typeToLabel } from '../../utils/utils';
import ModalContainerEdit from '../../components/modals/container-edit/ModalContainerEdit';
import apiService, { ApiResponse } from '../../services/apiService';
import dayjs from 'dayjs';

const typeFilters = [
  { id: -1, label: 'TODOS' },
  { id: 0, label: 'OUTRO' },
  { id: 1, label: 'DRY' },
  { id: 2, label: 'REFER' },
];

const statusFilters = [
  { id: -1, label: 'TODOS' },
  { id: 0, label: 'A RECEBER' },
  { id: 1, label: 'NO PATIO' },
  { id: 2, label: 'FINALIZADO' },
];

const stateFilters = [
  { id: -1, label: 'TODOS' },
  { id: 0, label: 'VAZIO' },
  { id: 1, label: 'CHEIO' },
];


type ModalType = 'edit' | 'delete' | 'entry' | 'exit' | null;

const ContainersPage: React.FC = () => {
  const [filters, setFilters] = useState({ driver_name: '', requerent: '', number: '', transporter_name: '', status: -1, type: -1, state: -1 });
  const [modalState, setModalState] = useState<{ type: ModalType; item?: any }>({
    type: null,
    item: null,
  });

  const [data, setData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });


  const handleOpenModal = (type: ModalType, item: any) => {
    setModalState({ type, item });
  };

  const handleCloseModal = () => {
    setModalState({ type: null, item: null });
  };


  const handleDelete = async () => {
    if (modalState.item) {
      try {
        const response = await apiService.delete(`/api/container?id=${modalState.item.key}`)
        notification.success({
          message: 'Sucesso',
          description: response.data.message,
        });
      } catch (error: any) {
        if (error.response && error.response.data) {
          const errorData: ApiResponse = error.response.data;
          notification.error({
            message: 'Erro',
            description: errorData.message || 'Ocorreu um erro inesperado',
          });
        } else {
          notification.error({
            message: 'Erro',
            description: 'Problema ao realizar operação',
          });
        }
      }
      fetchData(pagination.current, pagination.pageSize);
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  const handleFinalize = (item: any) => {
    switch (item.status) {
      case 0:
        handleOpenModal('entry', item);
        break;
      case 1:
        handleOpenModal('exit', item);
        break;
      case 2:
        notification.info({
          message: 'Informação',
          description: `Container ${item.number} já está FINALIZADO.`,
        });
        break;
      default:
        notification.error({
          message: 'Erro',
          description: `Status desconhecido para o container ${item.number}.`,
        });
    }
  };


  const handleFilterChange = (value: any, key: keyof typeof filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };


  const applyFilters = () => {
    fetchData(pagination.current, pagination.pageSize);
  };

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await apiService.get(
        `/api/containers?page=${page}&pageSize=${pageSize}&requerent=${filters.requerent}&driver_name=${filters.driver_name}&number=${filters.number}&status=${filters.status}&type=${filters.type}&state=${filters.state}&transporter_name=${filters.transporter_name}`
      );
      setData(response.data.data);
      setPagination({
        current: page,
        pageSize,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };


  return (
    <div className="containers-page">
      <h1>Registro de containers</h1>
      <Form layout="vertical" style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '12px' }}>
            <Form.Item label="Número" style={{ marginBottom: 8 }}>
              <Input
                placeholder="Número"
                value={filters.number}
                onChange={(e) => handleFilterChange(e.target.value, 'number')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '10px' }}>
            <Form.Item label="Requerente" style={{ marginBottom: 8 }}>
              <Input
                placeholder="Requerente"
                value={filters.requerent}
                onChange={(e) => handleFilterChange(e.target.value, 'requerent')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ width: '20px', marginRight: '12px' }}>
            <Form.Item label="Motorista" style={{ marginBottom: 4 }}>
              <Input
                placeholder="Nome do motorista"
                value={filters.driver_name}
                onChange={(e) => handleFilterChange(e.target.value, 'driver_name')}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '10px' }}>
            <Form.Item label="Transportadora" style={{ marginBottom: 8 }}>
              <Input
                placeholder="Transportadora"
                value={filters.transporter_name}
                onChange={(e) => handleFilterChange(e.target.value, 'transporter_name')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '12px' }}>
            <Form.Item label="Tipo" style={{ marginBottom: 8 }}>
              <Select
                placeholder="Tipo"
                value={filters.type}
                onChange={(value) => handleFilterChange(value, 'type')}
                style={{ width: '100%' }}
              >
                {typeFilters.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ marginRight: '12px' }}>
            <Form.Item label="Estado" style={{ marginBottom: 8 }}>
              <Select
                placeholder="Estado"
                value={filters.state}
                onChange={(value) => handleFilterChange(value, 'state')}
                style={{ width: '100%' }}
              >
                {stateFilters.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} >
            <Form.Item label="Status" style={{ marginBottom: 8 }}>
              <Select
                placeholder="Status"
                value={filters.status}
                onChange={(value) => handleFilterChange(value, 'status')}
                style={{ width: '100%' }}
              >
                {statusFilters.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={4} md={4} lg={6} xl={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button onClick={applyFilters} type="primary" style={{ width: '100%' }}>Filtrar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={data}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        loading={loading}
        onChange={handleTableChange}
      >
        <Table.Column title="Número" dataIndex="number" key="number" />
        <Table.Column title="Requerente" dataIndex="requerent" key="requerent" />
        <Table.Column title="Motorista" dataIndex="driver_name" key="driver_name" />
        <Table.Column title="Transportadora" dataIndex="transporter_name" key="transporter_name" />
        <Table.Column title="Tipo" dataIndex="type" key="type" render={(type) => typeToLabel(type)} />
        <Table.Column title="Estado" dataIndex="state" key="state" render={(state) => stateToLabel(state)} />
        <Table.Column title="Status do registro" dataIndex="status" key="status" render={(status) => statusToLabel(status)} />
        <Table.Column title="Previsão de entrada" dataIndex="estimated_entry_date" key="estimated_entry_date" render={(estimated_entry_date) => dayjs(estimated_entry_date).isValid() ? dayjs(estimated_entry_date).format('DD/MM/YYYY [às] HH:mm') : 'Data inválida'} />
        <Table.Column title="Preço 5 dias" dataIndex="price_five_days" key="price_five_days" render={(price_five_days) => `R$ ${price_five_days}`} />
        <Table.Column title="Preço/dia" dataIndex="price_per_day" key="price_per_day" render={(price_per_day) => `R$ ${price_per_day}`} />
        <Table.Column
          title="Ações"
          key="actions"
          render={(text, record) => {
            let buttonColor = '';
            if (record.status === 0) {
              buttonColor = 'blue';
            } else if (record.status === 1) {
              buttonColor = 'green';
            }

            return (
              <div className="actions">

                <Button
                  title='Editar'
                  icon={<EditOutlined />}
                  onClick={() => handleOpenModal('edit', record)}
                  style={{ marginRight: 8 }}
                />

                <Button
                  title='Excluir'
                  icon={<DeleteOutlined />}
                  onClick={() => handleOpenModal('delete', record)}
                  style={{ marginRight: 8 }}
                />
                {(record.status === 0 || record.status === 1) && (
                  <Button
                    title={record.status === 0 ? 'Registrar entrada' : 'Registrar saída'}
                    icon={<FlagOutlined />}
                    onClick={() => handleFinalize(record)}
                    type="primary"
                    style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
                  />
                )}
              </div>
            );
          }}
        />
      </Table>

      {modalState.type === 'edit' && (
        <ModalContainerEdit
          visible={modalState.type === 'edit'}
          onClose={handleCloseModal}
          driverData={modalState.item}
        />
      )}

      {modalState.type === 'delete' && (
        <Modal
          title="Confirmar Exclusão"
          open={modalState.type === 'delete'}
          onOk={handleDelete}
          onCancel={handleCloseModal}
        >
          <p>Tem certeza de que deseja excluir o container {modalState.item?.number}?</p>
        </Modal>
      )}

      {modalState.type === 'entry' && (
        <ModalContainerEntry
          visible={modalState.type === 'entry'}
          onClose={handleCloseModal}
          fetchData={fetchData}
          pageSize={pagination.pageSize}
          page={pagination.current}
          containerData={modalState.item}
        />
      )}

      {modalState.type === 'exit' && (
        <ModalContainerExit
          visible={modalState.type === 'exit'}
          onClose={handleCloseModal}
          fetchData={fetchData}
          pageSize={pagination.pageSize}
          page={pagination.current}
          initialContainerData={modalState.item}
        />
      )}
    </div>
  );
};

export default ContainersPage;
