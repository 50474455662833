import React from 'react';
import { stateToLabel } from '../../../utils/utils';
import { Modal, Form, DatePicker, notification, Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import apiService, { ApiResponse } from '../../../services/apiService';
dayjs.locale('pt-br');

interface ModalContainerEntryProps {
  visible: boolean;
  onClose: () => void;
  fetchData: (page: number, pageSize: number) => Promise<any>;
  containerData: any;
  page: number;
  pageSize: number;
}

const { Title, Text } = Typography;

const ModalContainerEntry: React.FC<ModalContainerEntryProps> = ({ visible, onClose, fetchData, containerData, page, pageSize }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (containerData) {
      const entryDate = containerData.entry_date ? dayjs(containerData.entry_date) : dayjs();
      form.setFieldsValue({
        ...containerData,
        entry_date: entryDate
      });
    }
  }, [containerData, form]);

  const handleSave = () => {
    form.validateFields()
      .then(async (values) => {
        try {
          const response = await apiService.put('/api/container/entry', {
            ...values,
            id: containerData.key,
            entry_date: values.entry_date
              ? dayjs(values.entry_date).toISOString()
              : null
          });
          notification.success({
            message: 'Sucesso',
            description: response.data.message,
          });
        } catch (error: any) {
          console.error('Erro ao registrar entrada do container:', error);
        }
        await fetchData(page, pageSize);
        onClose();
      }).catch((info) => {
        notification.error({
          message: 'Erro',
          description: 'Valide os campos obrigatórios',
        });
      });
  };

  return (
    <Modal
      title={<Title style={{ display: 'flex', justifyContent: 'center' }} level={4}>Registro de entrada do Container</Title>}
      open={visible}
      onOk={handleSave}
      onCancel={onClose}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Row gutter={[16, 16]} style={{ paddingTop: '20px' }}>
        <Col span={12}>
          <Text strong>Número:</Text> <Text>{containerData.number}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Transportadora:</Text> <Text>{containerData.transporter_name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Motorista:</Text> <Text>{containerData.driver_name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Estado do container:</Text> <Text>{stateToLabel(containerData.state)}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Preço/dia: </Text> <Text> R$ {containerData.price_per_day?.toFixed(2)}</Text>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '40px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 700 }}>Selecione a data de entrada do container</Text>
      </Row>
      <Row justify="center" style={{ paddingTop: '20px' }}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="entry_date"
            label="Data e Hora de Entrada"
            rules={[{ required: true, message: 'Por favor, selecione a data e hora de entrada' }]}
          >
            <DatePicker
              showTime
              format="DD/MM/YYYY HH:mm"
              className="datepicker-input"
            />
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};

export default ModalContainerEntry;
